.border-right-table{
    border-right: 1px solid rgb(15, 31, 243) !important;
}
.border-top-table{
    border-top: 1px solid rgb(15, 31, 243) !important;
}

.bg-primary-custom{
    background-color:rgba(54, 162, 235, 0.2);
    border:1px solid rgba(54, 162, 235, 1)
}
.bg-primary-custom:hover{
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2) !important;
            background-color: rgba(54, 162, 235, 0.4);
            transition: box-shadow 0.2s ease-in-out;
            border-radius: 10px;
}

.bg-success-custom-altern{
    background-color: rgba(9, 247, 247, 0.2);
        border: 1px solid rgb(4, 185, 185)
}
.bg-success-custom{
    background-color:rgba(85, 187, 187, 0.2);
    border:1px solid rgba(75, 192, 192, 1)
}

.bg-success-custom:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2) !important;
        background-color: rgba(75, 192, 192, 0.4);
            transition: box-shadow 0.2s ease-in-out;
                border-radius: 10px;
}

.bg-warning-custom{
    background-color:rgba(255, 206, 86, 0.2);
    border:1px solid rgba(255, 206, 86, 1);
}

.bg-warning-custom:hover  {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2) !important;
    background-color: rgba(255, 206, 86, 0.4);
                transition: box-shadow 0.2s ease-in-out;
                border-radius: 10px;
}

.bg-purple-custom{
    background-color:rgba(173, 86, 255, 0.2);
    border:1px solid rgb(213, 86, 255);
}

.bg-purple-custom:hover  {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2) !important;
    background-color: rgba(235, 86, 255, 0.4);
                transition: box-shadow 0.2s ease-in-out;
                border-radius: 10px;
}

.bg-danger-custom{
    background-color:rgba(255, 99, 132, 0.2);
    border:1px solid rgba(255, 99, 132, 1);
}

.bg-danger-custom:hover{
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2) !important;
        background-color: rgba(255, 99, 132, 0.4);
            transition: box-shadow 0.2s ease-in-out;
                border-radius: 10px;
}